<template>
  <div class="home">
    <div class="index-top">
      <div class="index-item">
        <div class="index-title">
          <h2>欢迎您，</h2>
          <p>来到教育管理控制台！</p>
          <div class="index-cal">
            <span>星期二</span>
            <em>2024.03.05</em>
          </div>
        </div>
        <div class="index-list">
          <div class="index-list-item">
            <div class="num">{{ statistics?.teacher_count }}<em>人</em></div>
            <p>教师数量</p>
          </div>
          <div class="index-list-item">
            <div class="num">{{ statistics?.student_count }}<em>人</em></div>
            <p>学生数量</p>
          </div>
          <div class="index-list-item">
            <div class="num">{{ statistics?.today_course_count }}<em>节</em></div>
            <p>今日课程数量</p>
          </div>
          <div class="index-list-item">
            <div class="num">{{ statistics?.today_complate_count }}<em>节</em></div>
            <p>今日消课数量</p>
          </div>
        </div>
      </div>
      <div class="index-item">
        <h3>重点关注</h3>
        <div class="less-top">
          <div class="less-img"></div>
          <div class="less-list">
            <div class="less-item">
              <span>剩余课时不足</span>
              <em>{{ statistics?.course_lack }}</em>
            </div>
            <div class="less-item huang">
              <span>充值余额不足</span>
              <em>{{ statistics?.balance_lack }}</em>
            </div>
<!--            <div class="less-item lv">-->
<!--              <span>会员即将到期</span>-->
<!--              <em>0</em>-->
<!--            </div>-->
          </div>
        </div>
        <div class="less-down">
          <div class="less-down-item">
            <div class="less-item hui">
              <span>剩余库存不足</span>
              <em>{{ statistics?.stock_lack }}</em>
            </div>
            <div class="less-item hui">
              <span>待处理课程</span>
              <em>{{ statistics?.wait_handle_course_count }}</em>
            </div>
            <div class="less-item hui">
              <span>近30天无上课记录</span>
              <em>{{ statistics?.no_attend }}</em>
            </div>
          </div>
          <div class="less-down-item">
            <div class="less-item hui">
              <span>新生未购课</span>
              <em>{{ statistics?.student_no_course }}</em>
            </div>
            <div class="less-item hui">
              <span>购课未排课</span>
              <em>{{ statistics?.no_timetable_count }}</em>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="index-down">
      <div class="index-down-left">
        <div class="index-down-title">学生剩余课时统计<em>（按课时由少至多排列）</em></div>
        <div class="index-down-list" v-for="(item,index) in timetableData">
          <div class="index-down-list-item">
            <div class="index-down-img">
              <img :src="item.avatar" alt="" />
            </div>
            <div class="index-down-text">
              <span>{{ item.name }}</span>
              <p>{{ item.phone }}</p>
            </div>
            <div class="index-down-section">{{ item.over_course_num }}节</div>
          </div>
        </div>
<!--        <div class="more">查看更多 ..</div>-->
      </div>
      <div class="index-down-right">
        <div class="index-down-right-title">最新消息</div>
        <div class="index-down-right-list">
          <div class="index-down-right-item" v-for="(item,index) in list" @click="dialogTap(item)">
            <div class="index-down-text1">
              <span>{{ dateHandle(item.date).week }}</span>
              <p>{{ dateHandle(item.date).year }}</p>
            </div>
            <div class="index-down-text2">
              <span>{{ item.title }}</span>
              <p>{{ item.descr }}</p>
            </div>
            <div class="index-down-text3">{{ item.date }}</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="formDrawerVisible" :width="600" :title="dialog.title" destroy-on-close>
      <div class="dialog-descr">{{ dialog.descr }}</div>
      <div class="dialog-cont" v-if="dialog.file_type === 1">
        <img :src="dialog.file_url" alt="" />
      </div>
      <div class="dialog-cont" v-if="dialog.file_type === 2">
        <a :href="dialog.file_url" download="">下载</a>
      </div>
      <div class="dialog-cont" v-if="dialog.file_type === 3">
        <audio :src="dialog.file_url"></audio>
      </div>
      <div class="dialog-cont" v-if="dialog.file_type === 4">
        <video ref="video" controls :src="dialog.file_url"></video>
      </div>
      <div class="dialog-date">{{ dialog.date }}</div>
    </el-dialog>
  </div>
</template>

<script>
import { homeStatistics, homeTimetable, noticeList } from '@/apis/common'

export default {
  data() {
    return {
      statistics: {},
      list: [],
      timetableData: [],
      formDrawerVisible: false,
      dialog: {}
    }
  },
  mounted() {
    if(sessionStorage.getItem('user')) {
      this.getHomeStatistics()
      this.getHomeTimetable()
      this.getNoticeList()
    }
  },
  methods: {
    dialogTap(e) {
      this.formDrawerVisible = true
      this.dialog = e
    },
    dateHandle(v) {
      let week = new Date(v).getDay()
      let year = new Date(v).getFullYear()
      let weekArr = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      return {
        year: year,
        week: weekArr[week]
      }
    },
    getNoticeList() {
      noticeList({
        page: 1,
        pagesize: 10,
        admin_id: JSON.parse(sessionStorage.getItem('user')).id,
      }).then(res => {
        this.list = res.data.data.list.filter(x => x.is_show === 1).slice(0, 3)
        console.log(this.list)
      })
    },
    getHomeTimetable() {
      homeTimetable({
        admin_id: JSON.parse(sessionStorage.getItem('user')).id,
        page: 1,
        pagesize: 10
      }).then(res => {
        console.log(res.data.data.data)
        this.timetableData = res.data.data.data
      })
    },
    getHomeStatistics() {
      homeStatistics({
        admin_id: JSON.parse(sessionStorage.getItem('user')).id
      }).then(res => {
        this.statistics = res.data.data
      })
    }
  }
};
</script>

<style scoped>
.dialog-cont img{
    width: 100%;
}
.dialog-cont video{
    width: 100%;
}
.dialog-cont audio{
    width: 100%;
}
.dialog-descr{
  font-size: 16px;
}
.dialog-date{
  text-align: right;
}
.index-down-text3{
    color: #fff;
    font-size: 16px;
    padding-top: 42px;
    text-align: right;
}
.index-down-text2{
    padding: 0 15px;
    color: #fff;
    width: calc(100% - 170px);
}
.index-down-text2>span{
    font-size: 20px;
    padding-bottom: 15px;
    display: inline-block;
}
.index-down-text2>p{
    font-size: 16px;
    color: #FEFEFE;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.index-down-text1{
    width: 50px;
    height: 72px;
    background: #FFFFFF;
    border-radius: 16px;
    text-align: center;
    font-size: 14px;
    color: #285BBA;
}
.index-down-text1>span{
    padding: 15px 0 5px 0;
    display: inline-block;
}
.index-down-right-item{
    padding: 25px 0;
    border-bottom: 1px solid #fff;
    display: flex;
    cursor: pointer;
}
.index-down-right-title{
    font-size: 26px;
    color: #fff;
    padding-left: 20px;
    position: relative;
}
.index-down-right-title:after{
    content: '';
    display: block;
    width: 4px;
    height: 80%;
    background-color: #fff;
    margin-top: 10px;
    position: absolute;
    top: -5px;
    left: 0;
    z-index: 1;
}
.more{
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
    color: #6597FC;
    cursor: pointer;
}
.index-down-list{
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
}
.index-down-list-item{
    width: calc(50% - 15px);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
    margin-top: 20px;
    margin-right: 15px;
    position: relative;
}
.index-down-text{
    padding-left: 65px;
}
.index-down-text>span{
    font-size: 18px;
    color: #364064;
}
.index-down-text>p{
    font-size: 16px;
    color: #8593C2;
}
.index-down-section{
    position: absolute;
    top: 50%;
    right: 10px;
    z-index: 1;
    color: #F55050;
    font-size: 18px;
    margin-top: -10px;
}
.index-down-img{
    position: absolute;
    top: 50%;
    left: 10px;
    z-index: 1;
    width: 45px;
    height: 45px;
    background-color: #72A1FC;
    border-radius: 50%;
    margin-top: -22px;
}
.index-down-img>img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.index-down-list-item:nth-child(2n){
    margin-right: 0;
    margin-left: 15px;
}
.index-down-title{
    font-size: 24px;
}
.index-down-title>em{
    font-size: 18px;
}
.index-down{
    margin-top: 30px;
    display: flex;
}
.index-down-left{
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
    width: calc(50% - 10px);
    padding: 20px;
    margin-right: 10px;
}
.index-down-right{
    border-radius: 20px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
    width: calc(50% - 10px);
    padding: 20px;
    margin-left: 10px;
    background-color: #8FB6F6;
}
.less-down{
    display: flex;
    margin-top: 20px;
    padding-top: 15px;
    border-top: 1px solid #A7A8A7;
}
.less-item{
    padding: 13px 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.less-down-item{
    width: 50%;
    box-sizing: border-box;
    padding-right: 20px;
}
.less-down-item:last-child{
    padding-right: 0;
    padding-left: 20px;
}
.less-item>span{
    font-size: 24px;
    padding-left: 30px;
    position: relative;
}
.less-item>em{
    font-size: 22px;
    text-align: center;
    width: 56px;
    height: 28px;
    box-shadow: 0 6px 7px 0 rgba(184,205,246,0.2);
    border-radius: 8px;
    border: 2px solid #4B82F2;
    display: inline-block;
    line-height: 28px;
    color: #4B82F2;
}
.less-item>span:after{
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
    width: 15px;
    height: 15px;
    box-sizing: border-box;
    content: '';
    border: 5px solid #6597FC;
    border-radius: 50%;
    margin-top: -6px;
}
.huang>span:after{
    border-color: #EAB680;
}
.lv>span:after{
    border-color: #7BEABD;
}
.hui>span:after{
    border-color: #B8B8B7;
}
.less-list{
    padding-left: 30px;
    width: calc(100% - 170px);
}
.less-top{
    display: flex;
}
.less-img{
    width: 170px;
    height: 170px;
}
.index-item>h3{
    font-size: 22px;
    color: #2B2B2B;
    padding: 20px 0;
    position: relative;
}
.index-item>h3:after{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    content: '';
    width: 40px;
    height: 8px;
    background-color: #6EE7B5;
    border-radius: 2px;
}
.index-top{
    display: flex;
}
.index-list{
    display: flex;
    flex-wrap: wrap;
}
.index-list-item{
    width: calc(50% - 40px);
    padding: 10px 30px 20px 30px;
    box-sizing: border-box;
    border-radius: 30px;
    margin-top: 40px;
}
.index-list-item:last-child{
    margin-right: 0;
    margin-left: 10px;
}
.index-list-item .num{
    font-size: 50px;
}
.index-list-item .num>em{
    font-size: 24px;
}
.index-list-item p{
    font-size: 20px;
    margin-top: 10px;
    color: #1C234C;
}
.index-list-item:nth-child(1){
    background: linear-gradient(180deg, #98F3E3 0%, #F6FEFD 100%);
    margin-right: 40px;
}
.index-list-item:nth-child(1) .num{
    color: #225D53;
}
.index-list-item:nth-child(2){
    background: linear-gradient(180deg, #8ECDFF 0%, #F6FEFD 100%);
    margin-left: 40px;
}
.index-list-item:nth-child(2) .num{
    color: #285BBA;
}
.index-list-item:nth-child(3){
    background: linear-gradient(180deg, #A7C0F8 0%, #F6FEFD 100%);
    margin-right: 40px;
}
.index-list-item:nth-child(3) .num{
    color: #37538C;
}
.index-list-item:nth-child(4){
    background: linear-gradient(180deg, #ECC8AA 0%, #FFFFFF 100%);
    margin-left: 40px;
}
.index-list-item:nth-child(4) .num{
    color: #A17045;
}
.index-item{
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
    width: calc(38% - 10px);
    padding: 20px;
    margin-right: 10px;
}
.index-item:last-child{
    margin-right: 0;
    margin-left: 10px;
    width: calc(62% - 10px);
}
.index-title{
    position: relative;
}
.index-title>h2{
    font-size: 30px;
    color: #4474D2;
}
.index-title>p{
    font-size: 22px;
    color: #356BD4;
}
.index-cal{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #6597FC;
    display: flex;
    overflow: hidden;
}
.index-cal>span{
    display: block;
    padding: 5px;
    background-color: #6597FC;
    color: #fff;
}
.index-cal>em{
    display: block;
    padding: 5px;
    color: #6597FC;
}
</style>

